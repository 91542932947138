import Sticky from 'sticky-js/dist/sticky.min'

var sticky = new Sticky('.fixed-sidebar')

const stickySidebar = () => {
  if ($('.fixed-sidebar').length) {
    $('.fixed-sidebar').each(function() {
      const $this = $('.fixed-sidebar')
      $this.parent().attr('data-sticky-container', '')
      $this.attr('data-margin-top', $('.banner').outerHeight())
    })
  }
}

$(document).ready(function() {
  stickySidebar()
})

sticky.update()
