import 'slick-carousel/slick/slick.js'
$(document).ready(function() {
  $('.dhsv_vc_posts_slider').each(function() {
    let count = $(this).data('slides')
    $(this).slick({
      fade: false,
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: count,
      slidesToScroll: count ? count : '4',
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  })
})
