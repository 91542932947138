import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import news from './news'
import insights from './insights'

const appReducer = combineReducers({
  browser,
  location: router,
  news,
  insights,
})

export default appReducer
