import 'simplebar'
import 'jquery-match-height/dist/jquery.matchHeight-min'

const resizeFullWidthRow = () => {
  $('[data-vc-full-width="true"]').each(function() {
    $(this).css({
      width: document.body.clientWidth,
      marginLeft: -document.body.clientWidth / 2,
    })
    if (!$(this).attr('data-vc-stretch-content')) {
      let padding =
        (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
      $(this).css({
        'padding-left': padding,
        'padding-right': padding,
      })
    }
  })
}

const positionGformButton = () => {
  let heightSide = $('.gform_wrapper .gfmc-row-1-col-2-of-2').height()
  let heightHeading = $('.gform_heading').height()
  let heightButton = $('.gform_wrapper .gform_footer').height() + 50
  let totalHeightButton = heightHeading + heightSide + 25
  $('.gform_wrapper .gform_footer').css('top', totalHeightButton + 'px')
  $('.gform_wrapper .gfmc-row-1-col-2-of-2').css(
    'paddingBottom',
    heightButton + 'px'
  )
}

const formsHandler = () => {
  $('input,textarea', '.gfield').each(function() {
    $(this).attr('placeholder', '')
    if (
      !$(this)
        .closest('.gfield')
        .find('.gfield_label')
        .hasClass('active')
    ) {
      if ($(this).val()) {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      }
      $(this).on('focusin', function() {
        $(this)
          .closest('.gfield')
          .find('.gfield_label')
          .addClass('active')
      })

      $(this).on('focusout', function() {
        if (!$(this).val()) {
          $(this)
            .closest('.gfield')
            .find('.gfield_label')
            .removeClass('active')
        }
      })
    }
  })
}

const stickyHeader = () => {
  const top = $('.banner').outerHeight() - 50
  $(window).scroll(function() {
    let y = $(this).scrollTop()
    if (y >= top) {
      $('.banner').addClass('fixed')
    } else {
      $('.banner').removeClass('fixed')
    }
  })
}

const dropdownMenu = () => {
  if ($(window).width() > 991) {
    $('.dropdown-toggle').mouseenter(function() {
      $(this)
        .closest('.nav-item')
        .find('.dropdown-menu')
        .addClass('opened')
    })
    $('.dropdown-toggle').one('click', function(e) {
      e.preventDefault()
      $(this)
        .closest('.nav-item')
        .find('.dropdown-menu')
        .addClass('opened')
    })
    $('.dropdown-menu').mouseenter(function() {
      $(this).addClass('opened')
    })
    $('.dropdown-toggle').mouseleave(function() {
      $(this)
        .closest('.nav-item')
        .find('.dropdown-menu')
        .removeClass('opened')
    })
    $('.dropdown-menu').mouseleave(function() {
      $(this).removeClass('opened')
    })
  } else {
    $('.mobile-dropdown-toggle').on('click', function() {
      if ($(this).hasClass('opened')) {
        $(this)
          .removeClass('opened')
          .next('.dropdown-menu')
          .removeClass('opened')
      } else {
        $(this)
          .addClass('opened')
          .next('.dropdown-menu')
          .addClass('opened')
      }
    })
  }
}

const mobileMenuInit = () => {
  $('.js-menu-btn').click(function() {
    $(this).toggleClass('js-opened')
    $('.banner').toggleClass('js-opened')
  })
}

export const underlineText = elements => {
  ellipsizeTextBox(elements)
  underlineTextBox(elements)
}

const ellipsizeTextBox = items => {
  items.each(function(index, el) {
    const rows = $(el).data('rows')
    let wordArray = $(el).data('text')
    if (rows && rows !== 'full' && wordArray) {
      const rowHeight = Math.round(parseFloat($(el).css('line-height')))
      wordArray = wordArray.split(' ')
      el.innerHTML = wordArray.join(' ')
      while ($(el).height() > parseInt(rows) * rowHeight) {
        wordArray.pop()
        el.innerHTML = wordArray.join(' ') + '...'
      }
      $(el).removeClass('styled')
    }
  })
}

const underlineTextBox = items => {
  items.each(function(index, el) {
    if (!$(el).hasClass('styled')) {
      const html = $(el).html()
      if (html) {
        $(el).html('')
        const wordArray = html.split(' ')
        wordArray.map(item => {
          $(el).append('<span class="word">' + item + '</span>')
        })
      }
      $(el).addClass('styled')
    }
  })
}

const scrollDown = () => {
  $('body').on('click', '.js-scrolldown', function() {
    const $wrapper = $(this).closest('.vc_row')

    $('html, body').animate(
      {
        scrollTop: $wrapper.offset().top + $wrapper.outerHeight() - 114,
      },
      600
    )
  })
}

const smoothScroll = (link, e) => {
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }

  if (hash && $(hash).length) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top - 114,
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

const modalInit = () => {
  $('body').on('click', '.js-modal-open', function() {
    const modal = $(this).data('modal')
    $(modal)
      .fadeIn()
      .addClass('is-open')

    $(document).mouseup(function(e) {
      if (
        !$(modal).is(e.target) &&
        $(modal).has(e.target).length === 0 &&
        $(modal).hasClass('is-open')
      ) {
        $(modal).fadeOut()
      }
    })
  })

  $('body').on('click', '.js-modal-close', function() {
    const modal = $(this).closest('.js-side-modal')
    $(modal)
      .fadeOut()
      .removeClass('is-open')
  })
}

const isInViewport = elem => {
  const distance = elem.getBoundingClientRect()
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  )
}

const removeHash = () => {
  history.replaceState(
    '',
    document.title,
    window.location.origin + window.location.pathname + window.location.search
  )
}

export default {
  init() {
    // JavaScript to be fired on all pages

    resizeFullWidthRow()
    stickyHeader()
    dropdownMenu()
    mobileMenuInit()
    formsHandler()
    positionGformButton()
    scrollDown()
    modalInit()
    $('.imageHeight').matchHeight()
    underlineText($('.underlined'))

    $(document).bind('gform_post_render', function() {
      formsHandler()
      positionGformButton()
    })

    removeHash()

    $(document).ready(function() {
      $('body').addClass('loaded')
    })

    $(window).on('load', function() {
      underlineText($('.underlined'))
      //$('body').addClass('loaded')

      /*   var videos = document.getElementsByClassName('main-video')
      var navigation = document.querySelector('.banner')

      for (var i = 0; i < videos.length; i++) {
        videos[i].addEventListener('play', function() {
          navigation.style.display = 'none'
          $('.banner + div').addClass('space')
        })

        videos[i].addEventListener('pause', function() {
          navigation.style.display = 'block'
          $('.banner + div').removeClass('space')
        })
      }*/

      var videos = document.getElementsByClassName('main-video')
      var navigation = document.querySelector('.banner')

      for (var i = 0; i < videos.length; i++) {
        videos[i].addEventListener('play', function() {
          navigation.style.display = 'none'
          $('.banner + div').addClass('space')
        })

        videos[i].addEventListener('pause', function() {
          navigation.style.display = 'block'
          $('.banner + div').removeClass('space')
        })

        videos[i].addEventListener('ended', function() {
          navigation.style.display = 'block'
          $('.banner + div').removeClass('space')
        })
      }

      $(document).on('click', '.hero-animation__intro', function() {
        navigation.style.display = 'none'
        $('.banner + div').addClass('space')
      })

      $(document).on('click', '.main-video__skip', function() {
        navigation.style.display = 'block'
        $('.banner + div').removeClass('space')
      })

      const underlined = document.querySelectorAll('.underlined')

      underlined.forEach(element => {
        if (isInViewport(element)) {
          element.classList.add('active')
        } else {
          element.classList.remove('active')
        }
      })

      window.addEventListener(
        'scroll',
        () => {
          underlined.forEach(element => {
            if (isInViewport(element)) {
              element.classList.add('active')
            } else {
              element.classList.remove('active')
            }
          })
        },
        false
      )
    })

    $(window).on('resize', function() {
      resizeFullWidthRow()
      positionGformButton()
      //dropdownMenu()
      underlineText($('.underlined'))
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      function(e) {
        smoothScroll($(this).attr('href'), e)
      }
    )

    if (window.location.hash) {
      smoothScroll(window.location.hash)
    }

    //Open New window on share
    $('.share-button').click(function(e) {
      e.preventDefault()
      var wpWidth = $(window).width()
      var wpHeight = $(window).height()
      window.open(
        $(this).attr('href'),
        'Share',
        'top=' +
          (wpHeight - 400) / 2 +
          ',left=' +
          (wpWidth - 600) / 2 +
          ',width=600,height=400'
      )
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
