import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_portfolio()
})

let resizeTimer

$(window).on('resize', function() {
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function() {
    dhsv_vc_portfolio()
  }, 250)
})

function dhsv_vc_portfolio() {
  var target = window.location.hash
  target = target.replace('#', '')
  window.location.hash = ''

  let tabs = $('.tabbed-content').find('.tabs')
  if (tabs.is(':visible')) {
    setTimeout(function() {
      tabs.find('a[href="#' + target + '"]').click()
    }, 1000)

    tabs.find('a').on('click', function(event) {
      event.preventDefault()
      let target = $(this).attr('href'),
        tabs = $(this).parents('.tabs'),
        buttons = tabs.find('a'),
        item = tabs.parents('.tabbed-content').find('.item')
      history.replaceState(null, '', target)
      buttons.removeClass('active')
      item.removeClass('active')
      $(this).addClass('active')
      $(target).addClass('active')
      $('html, body').animate(
        { scrollTop: tabs.parents('.tabbed-content').offset().top - 70 },
        500
      )
    })
  } else {
    setTimeout(function() {
      $('#' + target).click()
    }, 1000)

    $('.item').on('click', function() {
      let container = $(this).parents('.tabbed-content'),
        currId = $(this).attr('id'),
        items = container.find('.item')
      container.find('.tabs a').removeClass('active')
      items.removeClass('active')
      $(this).addClass('active')
      container.find('.tabs a[href$="#' + currId + '"]').addClass('active')
      $('html, body').animate({ scrollTop: container.offset().top - 70 }, 500)
    })
  }
}
