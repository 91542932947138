import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  insights: {},
}

const LOAD_INSIGHTS_DATA = 'LOAD_INSIGHTS_DATA'
const LOAD_INSIGHTS_DATA_SUCCESS = 'LOAD_INSIGHTS_DATA_SUCCESS'
const LOAD_INSIGHTS_DATA_FAILURE = 'LOAD_INSIGHTS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_INSIGHTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_INSIGHTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        insights: { ...state.insights, ...payload },
        error: false,
      }

    case LOAD_INSIGHTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadPosts = (offset, per_page, term, id) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_INSIGHTS_DATA })

  let currentPost = getState().insights.insights[id]

  if (currentPost === undefined) {
    currentPost = new Array()
  }

  return fetch(
    `${WP_API_BASE}insights?offset=${offset}&per_page=${per_page}&term=${term}`,
    {
      method: 'GET',
    }
  )
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(posts => {
      let payload = {}

      if (term === 0) {
        payload[id] = posts
      } else {
        currentPost[term] = currentPost[term].concat(posts)
        payload[id] = currentPost
      }

      dispatch({
        type: LOAD_INSIGHTS_DATA_SUCCESS,
        payload,
      })

      return posts
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_INSIGHTS_DATA_FAILURE,
      })
    })
}
